
import { Component, Prop } from 'vue-property-decorator';
import { Branding } from '@govflanders/mbp-admin-panel-shared';
import { BrandingLevelsMixin } from '../branding-levels-mixin';

@Component({})
export default class BrandingLevelBlock extends BrandingLevelsMixin {
  @Prop({
    type: Object,
    required: false,
  })
  public value: Branding;

  public setBrandingLevelNumber(val: string): void {
    this.value.level = Number(val);
  }
}
