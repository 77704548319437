import { PropOptions } from 'vue/types/umd';

/**
 * Type which describes a search query data field value.
 */
export type SearchQueryDataPropertyValue = any;

/**
 * Interface which describes a search query data.
 */
export interface SearchQueryData {
  /**
   * Support custom query filter properties and values.
   */
  [key: string]: SearchQueryDataPropertyValue;
}

/**
 * Component property options for SearchQueryData.
 *
 * @var {PropOptions<SearchQueryData>}
 */
export const SEARCH_QUERY_DATA_PROP_OPTIONS = {
  type: Object,
} as PropOptions<SearchQueryData>;
