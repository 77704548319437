import AsyncLock from 'async-lock';
import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { SearchResponse, SearchPageMetaData, SearchQueryData } from '@/utility/definitions';
import { IpdcProduct } from '@govflanders/mbp-admin-panel-shared';
import { IPDC_PRODUCT_SERVICE, IpdcProductService } from '@/services/ipdc';

@Module({ namespaced: true })
export class IpdcProductSearchModule extends VuexModule {
  public response: SearchResponse<IpdcProduct> | null = null;
  public error: Error | null = null;

  /**
   * Internal async lock used to prevent multiple search operations from
   * conflicting.
   *
   * @var {AsyncLock}
   */
  private _lock = new AsyncLock();

  @Inject(IPDC_PRODUCT_SERVICE)
  private IpdcProductService!: IpdcProductService;

  public get isFailed(): boolean {
    return this.error !== null;
  }

  public get isSuccess(): boolean {
    return !this.isFailed && !this.isLoading;
  }

  public get isLoading(): boolean {
    return !this.isFailed && this.response === null;
  }

  public get results(): IpdcProduct[] {
    if (this.response && this.isSuccess) {
      return this.response.items;
    }

    return [];
  }

  public get pageMetaData(): SearchPageMetaData | null {
    if (this.response && this.isSuccess) {
      return this.response.pageMetadata;
    }

    return null;
  }

  @Mutation
  public setLoading(): void {
    this.response = null;
    this.error = null;
  }

  @Mutation
  public setSuccess(searchResponse: SearchResponse<IpdcProduct>): void {
    this.response = searchResponse;
    this.error = null;
  }

  @Mutation
  public setFailed(error: Error): void {
    this.error = error;
    this.response = null;
  }

  @Action
  public async search(queryData: SearchQueryData): Promise<void> {
    // Do not allow parallel execution to prevent conflicting states to be resolved or
    // the results to get out of sync with the query.
    return this._lock.acquire('execute', async () => {
      this.context.commit('setLoading');
      await this.IpdcProductService.find(queryData)
        .then(response => {
          this.context.commit('setSuccess', response);
        })
        .catch(error => {
          this.context.commit('setFailed', error);
          console.log(error);
        });
    });
  }
}
