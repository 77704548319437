
import { Component, Prop } from 'vue-property-decorator';
import JsonEditor from '@/components/shared/forms/JsonEditor.vue';
import { getBrandingSchema } from '@/utility/branding';
import { Branding } from '@govflanders/mbp-admin-panel-shared';
import { v4 as uuidv4 } from 'uuid';

import BrandingMetaDataBlock from './form-blocks/BrandingMetaDataBlock.vue';
import BrandingUmbrellaBlock from './form-blocks/BrandingUmbrellaBlock.vue';
import BrandingHostBlock from './form-blocks/BrandingHostBlock.vue';
import BrandingColorsBlock from './form-blocks/BrandingColorsBlock.vue';
import BrandingOrganizationsBlock from './form-blocks/BrandingOrganizationsBlock.vue';
import MultiLanguageSection from '../shared/forms/MultiLanguageSection.vue';
import BrandingLevelBlock from './form-blocks/BrandingLevelBlock.vue';
import BrandingLogosBlock from './form-blocks/BrandingLogosBlock.vue';
import { BrandingLevelsMixin } from './branding-levels-mixin';

@Component({
  components: {
    JsonEditor,
    BrandingMetaDataBlock,
    BrandingUmbrellaBlock,
    BrandingHostBlock,
    BrandingColorsBlock,
    BrandingOrganizationsBlock,
    MultiLanguageSection,
    BrandingLevelBlock,
    BrandingLogosBlock,
  },
})
export default class BrandingForm extends BrandingLevelsMixin {
  public schema: object;

  @Prop({
    type: Object,
    required: false,
  })
  public value: Branding;

  public created() {
    this.schema = getBrandingSchema();

    if (!this.currentValue.id) {
      this.currentValue.id = uuidv4();
    }

    if (!this.currentValue.host) {
      this.currentValue.host = {
        label: '',
        href: '',
      };
    }
  }

  public get currentValue(): Branding {
    return this.value;
  }

  public set currentValue(value: Branding) {
    this.$emit('input', value);
  }
}
