import { PropOptions } from 'vue';

/**
 * Interface which describes a search page meta.
 */
export interface SearchPageMetaData {
  /**
   * Maximum number of results for a single page.
   *
   * @var {number}
   */
  readonly size?: number;

  /**
   * Maximum number of results for a single page.
   *
   * @var {number}
   */
  readonly limit?: number;

  /**
   * Zero-based search page offset.
   *
   * @var {number}
   */
  readonly offset?: number;

  /**
   * Total number of search results matching search query data.
   *
   * @var {number}
   */
  readonly totalItems?: number;
}

/**
 *  Validate whether given page meta data is valid.
 *
 * @param {SearchPageMetaData} pageMetaData
 *   An object which represents the search page meta data.
 *
 * @return {boolean}
 *   True if valid, otherwise false.
 */
export function isValidSearchPageMetaData(pageMetaData: SearchPageMetaData): boolean {
  return (
    pageMetaData !== null &&
    typeof pageMetaData === 'object' &&
    typeof pageMetaData.offset === 'number' &&
    typeof pageMetaData.size === 'number' &&
    typeof pageMetaData.totalItems === 'number'
  );
}

/**
 * Component property options for SearchPageMetaData.
 *
 * @var {PropOptions<SearchPageMetaData>}
 */
export const SEARCH_PAGE_META_DATA_PROP_OPTIONS = {
  type: Object,
  validator: isValidSearchPageMetaData,
} as PropOptions<SearchPageMetaData>;
