
import { Widget } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WidgetEmbed extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  public isV5!: boolean;

  /**
   * Holds the widget config for which metadata should be shown.
   *
   * @var {Widget}
   */
  @Prop({
    type: Object,
    required: true,
  })
  public value!: Widget;
  public parsedEmbedScript = ((window as any).__CONFIG__.env !== 'production'
    ? `&lt;script src="https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/${
        this.isV5 ? 'v2' : 'v1'
      }/widget/${this.value.id}/embed"&gt;&lt;/script&gt;`
    : `&lt;script src="https://prod.widgets.burgerprofiel.vlaanderen.be/api/${
        this.isV5 ? 'v2' : 'v1'
      }/widget/${this.value.id}/embed"&gt;&lt;/script&gt;`
  )
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');

  public handleCopyEmbedcode(): void {
    // copy uuid to clipboard
    navigator.clipboard.writeText(this.parsedEmbedScript);
  }
}
