
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Branding, BrandingUmbrella, ConfigValueName } from '@govflanders/mbp-admin-panel-shared';

import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';

@Component({
  components: {
    MultiLanguageInput,
  },
})
export default class BrandingUmbrellaBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: BrandingUmbrella;

  @Prop({
    type: Object,
    required: true,
  })
  public base: Branding;

  public get currentValue(): BrandingUmbrella {
    return this.value;
  }

  public set currentValue(value: BrandingUmbrella) {
    this.$emit('input', value);
  }
}
