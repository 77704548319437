import { Vue } from 'vue-property-decorator';
import {
  BRANDING_LEVEL_1,
  BRANDING_LEVEL_2,
  BRANDING_LEVEL_3,
  BRANDING_LEVEL_4,
} from './branding-levels';

export class BrandingLevelsMixin extends Vue {
  public readonly BRANDING_LEVEL_1 = BRANDING_LEVEL_1;
  public readonly BRANDING_LEVEL_2 = BRANDING_LEVEL_2;
  public readonly BRANDING_LEVEL_3 = BRANDING_LEVEL_3;
  public readonly BRANDING_LEVEL_4 = BRANDING_LEVEL_4;
}
