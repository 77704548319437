
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import { Widget } from '@govflanders/mbp-admin-panel-shared';

@Component
export default class WidgetPreviewData extends Vue {
  @Prop({
    type: Object as PropType<Widget>,
    required: false,
  })
  public value: Widget;

  public isPreviewAvailable = false;

  public mounted() {
    if (this.value && this.value.origins) {
      this.isPreviewAvailable = this.value.origins.includes(document.location.origin);
    }
  }

  public handleCopy(version: string = 'v4'): void {
    navigator.clipboard.writeText(this.widgetPreviewUrl(version));
  }

  public widgetPreviewUrl(version: string = 'v4'): string {
    const type = this.value.pluginTypeId === 'global_header' ? 'header' : 'footer';
    return `${window.location.origin}/widgets/preview?${type}=${this.value.id}&version=${version}`;
  }

  public setIsPreviewAvailable(newValue: boolean): void {
    this.isPreviewAvailable = newValue;

    const origin = document.location.origin;
    if (this.isPreviewAvailable) {
      if (!this.value.origins.includes(origin)) {
        this.value.origins.push(origin);
      }
    } else {
      if (this.value.origins.indexOf(origin) >= 0) {
        this.value.origins.splice(this.value.origins.indexOf(origin), 1);
      }
    }

    this.$emit('input', this.value);
  }
}
