
import { Component, Vue, Prop } from 'vue-property-decorator';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';

@Component({
  components: {
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
  },
})
export default class ContactDelete extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public id!: string;
  public deleteOngoing = false;

  public deleteContact() {
    this.deleteOngoing = true;
    this.$store
      .dispatch('branding/delete', this.id)
      .then((success) => {
        if (success) {
          this.$router.push({ name: 'branding' });
          this.$toast.success(this.$t('branding.delete.success'));
        } else {
          this.$toast.error(this.$t('branding.delete.error'));
        }
      })
      .catch((e) => {
        console.error(e);
        this.$toast.error(this.$t('branding.delete.error'));
        this.deleteOngoing = false;
      });
  }

  public cancel() {
    this.$router.go(-1);
  }
}
