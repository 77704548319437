
import {
  LanguageKey,
  Translations,
  Widget,
  WidgetExtension,
  languageOptions,
} from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class MultiLanguageSection extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  value: Widget | WidgetExtension;

  readonly options = languageOptions;

  created() {
    if (!this.value.i18n) {
      const i18n = {
        default: 'nl',
        translations: {
          nl: {},
        },
      };
      this.$set(this.value, 'i18n', i18n);
    }

    this.handleExtensionsTranslation(this.value);
  }

  mounted() {
    this.handleTranslations();
  }

  @Watch('value.extensions', { deep: false })
  onExtensionsChanged() {
    this.handleExtensionsTranslation(this.value);
  }

  handleTranslations() {
    if (this.value.i18n && this.value.i18n.translations && this.value.i18n.translations.nl)
      this.prefillEmptyValues(this.value.i18n.translations.nl, [], this.value);
  }

  handleExtensionsTranslation(value: Widget | WidgetExtension) {
    value.extensions &&
      value.extensions.forEach((extension) => {
        const translations: Translations = {};

        const i18n = {
          default: this.value.i18n.default,
          translations,
        };

        // Add the translations of the current extension to the translations object
        Object.keys(this.value.i18n.translations).forEach((language) => {
          i18n.translations[language] = extension.i18n
            ? extension.i18n.translations[language] || {}
            : {};
        });

        // In case it's the first time the page is loaded with multi language context,
        // The dutch translations need to be prefilled with the existing values
        if (this.languages.includes('nl')) {
          // The MultiLanguage{Component}'s are creating the object structure for their values.
          // We're piggybacking on that structure to prefill the dutch values
          if (extension.i18n) {
            this.prefillEmptyValues(extension.i18n.translations.nl, [], extension);
          }
        }

        this.$set(extension, 'i18n', i18n);

        // If this extension has extensions, handle those as well
        if (extension.extensions) {
          this.handleExtensionsTranslation(extension);
        }
      });
  }

  // Get a deep value of an object based on a path to the value
  getValue(base, path) {
    if (path.length === 1) {
      return base[path[0]];
    }

    return this.getValue(base[path[0]], path.slice(1));
  }

  prefillEmptyValues(
    base: object | object[] | string | undefined | null,
    path: (string | number)[],
    source: object,
    parent?: object | object[],
  ) {
    // If the base is not an object, we're at the end of the path
    if (!base || typeof base !== 'object') {
      // If the value is empty, set it to the value of the source object at the same path
      if (base === undefined || base === null || base === '') {
        // Get the parent value of the value we want to set, so it's correctly set by reference
        const sourceValue = this.getValue(source, path);

        // Set the value to the value of the source object at the same path
        this.$set(parent, path[path.length - 1], sourceValue || '');
      }
    } else {
      // If the base is an object|array, traverse each item in the object|array
      if (Array.isArray(base)) {
        base.forEach((item, index) => {
          this.prefillEmptyValues(item, [...path, index], source, base);
        });
      } else {
        for (const key in base) {
          this.prefillEmptyValues(base[key], [...path, key], source, base);
        }
      }
    }
  }

  get languages() {
    return Object.keys(this.value.i18n.translations) as LanguageKey[];
  }

  defaultLanguageChanged(newDefaultLanguage: LanguageKey) {
    const i18n = { ...this.value.i18n, default: newDefaultLanguage };

    if (!this.languages.includes(newDefaultLanguage)) {
      i18n.translations[newDefaultLanguage] = {};
    }

    this.$set(this.value, 'i18n', i18n);
    this.handleExtensionsTranslation(this.value);
  }

  languagesChanged(newLanguages: LanguageKey[]) {
    const i18n = { ...this.value.i18n, translations: {} };

    newLanguages.forEach((language) => {
      i18n.translations[language] = this.value.i18n.translations[language] || {};
    });

    if (!newLanguages.includes(this.value.i18n.default)) {
      i18n.translations[this.value.i18n.default] =
        this.value.i18n.translations[this.value.i18n.default] || {};
    }

    this.$set(this.value, 'i18n', i18n);
    this.handleExtensionsTranslation(this.value);
  }

  getLabel(language: LanguageKey) {
    return this.$t(`translations.${language}`);
  }
}
