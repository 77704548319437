
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Branding } from '@govflanders/mbp-admin-panel-shared';

import UuidField from '@/components/shared/forms/UuidField.vue';
import { Getter } from 'vuex-class';
import { debounce } from 'lodash';

@Component({
  components: {
    UuidField,
  },
})
export default class BrandingMetaDataBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: Branding;

  @Getter('organisationSearch/isLoading')
  public organisationNamesLoading: boolean;

  @Getter('organisationSearch/results')
  public organisationNames: string[];

  public loadOrganisationNames = debounce(
    (query) => this.$store.dispatch('organisationSearch/search', { query }),
    300,
  );

  public get currentValue(): Branding {
    return this.value;
  }

  public set currentValue(value: Branding) {
    this.$emit('input', value);
  }
}
