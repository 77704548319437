
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Widget } from '@govflanders/mbp-admin-panel-shared';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';

@Component({
  components: {
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
  },
})
export default class WidgetDelete extends Vue {
  /**
   * Holds the widget identifier.
   *
   * @var {string}
   */
  @Prop({
    type: String,
    required: true,
  })
  public id!: string;

  public deleteLoading = false;

  public async config(): Promise<Widget | null> {
    return this.$store.dispatch('widget/get', this.id);
  }

  public deleteWidget() {
    this.deleteLoading = true;
    // Delete the given widget config.
    this.$store
      .dispatch('widget/delete', this.id)
      .then(() => {
        // TODO: Should provide translation support.
        this.$toast.success('Widget was successfully deleted');
        // Redirect to the widget search page
        this.$router.push({ name: 'widgets' });
      })
      .catch(() => {
        this.deleteLoading = false;

        // TODO: Should provide translation support.
        this.$toast.error('Failed to delete widget');
      });
  }

  public cancel() {
    this.$router.go(-1);
  }
}
