
import { Getter } from 'vuex-class';

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Branding, ConfigValueName } from '@govflanders/mbp-admin-panel-shared';

import BrandingEntitylogoBlock from './BrandingEntitylogoBlock.vue';
import MultiLanguageImageUpload from '@/components/shared/MultiLanguageImageUpload.vue';
import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';

@Component({
  components: {
    BrandingEntitylogoBlock,
    MultiLanguageImageUpload,
    MultiLanguageInput,
  },
})
export default class BrandingLevelBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: Branding;

  @Getter('config/get')
  private getConfig: (value: ConfigValueName) => string;

  public get widgetsDirectory(): string {
    return this.getConfig(ConfigValueName.CLOUDINARY_FILE_PREFIX);
  }
}
