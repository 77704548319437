
import { Component, Prop, Vue } from 'vue-property-decorator';

interface MetaDataValue {
  id: string;
  type: string;
}

@Component
export default class MetaData extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value!: MetaDataValue;
}
