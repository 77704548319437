enum Platforms {
  IOS = 'ios',
  ANDROID = 'android',
  ALL = 'all',
}

enum MessageTypes {
  END_OF_LIFE = 'end_of_life',
  INFO = 'info',
  MAINTENANCE = 'maintenance',
  THEME_INFO = 'theme_info',
}

enum MessageActionTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  WEBVIEW = 'webview',
}

export enum ReportConnectionType {
  EMAIL = 'email',
  VICTOR = 'victor',
  NONE = 'none',
}

enum RssFeedCategories {
  MAIN = 'main',
  NEWS = 'news',
}

type Appointment =
  | Record<string, undefined> // Inactive
  | {
      // External link
      type: 'external';
      fallback: string;
    }
  | {
      // JCC
      type: 'jcc';
      url: string;
      username: string;
      password: string;
      fallback?: string;
      useExtendedCalls?: boolean;
      excludedDescriptionPrefix?: string;
    };

const LinkTypes = ['WEBSHOP', 'RESERVATION', 'TOURISM', 'RECYCLING', 'THE_CLICK'] as const;

type GenericLink<T = string> = {
  type: T;
  url: string;
  label: string;
};

type Link = GenericLink<(typeof LinkTypes)[number]>;

const Themes = [
  'mobility',
  'work',
  'education',
  'real-estate',
  'family',
  'covid',
  'culture',
  'justice',
] as const;

type ThemeLink = GenericLink<(typeof Themes)[number]>;

type RssFeed = {
  url: string;
  category: string;
  label: string;
};

export enum CategoryType {
  MAIL = 'mail',
  EXTERNAL = 'external',
}

export interface ReportCategoryDto {
  id: string;
  name: string;
  subCategories?: ReportCategoryDto[];
  type?: CategoryType;
  fallback?: string;
  to?: string;
}

type Message = {
  id: string;
  platform: Platforms;
  type: MessageTypes;
  from: string; //Datetime
  to?: string; //Datetime
  appVersion: string; //semVer
  platformVersion: string; //Datetime
  message?: string; // only needed if type is info
  action?: {
    label?: string;
    url: string;
    type: MessageActionTypes; // ['internal', 'external', 'webview']
  };
};

type MBPApp = {
  name: string;
  id?: string;
  app: {
    name: string;
    isLive?: boolean;
    android: string;
    ios: string;
    iosAppId: string;
    icon: string;
  };
  acm?: {
    clientId: string;
    clientSecret: string;
  };
  widgetId?: string;
  contactIds: string[];
  colors: {
    primary: string;
    action: string;
  };
  fontFamily: string;
  dashboardLogo?: string;
  rssFeed: RssFeed[];
  privacyPolicy: string;
  postalCodes: number[];
  nisCodes: number[];
  appointment: Appointment;
  uitApiKey?: string;
  report: {
    usesService: boolean;
    fallback?: string;
    type?: ReportConnectionType;
    to?: string;
    categories?: ReportCategoryDto;
  };
  functionalServices: string[];
  features: string[];
  links: Link[];
  themeLinks: ThemeLink[];
  messages?: Message[];
  forceUpgrade?: string;
  createdAt: string;
  lastModifiedAt: string;
  kboCode: string;
  organisationCode: string;
  slimMapUrl?: string;
  faqUrl?: string;
  localJobs?: {
    url?: string;
    label?: string;
  };
};
// Note if you add properties here. You'll have to add them to mbp-config-client as well
// see: https://bitbucket.org/vlaamseoverheid/mbp-config-client

export { LinkTypes, Themes, Platforms, RssFeedCategories, MessageTypes, MessageActionTypes };
export type { MBPApp, ThemeLink, GenericLink, Link, RssFeed, Message };
