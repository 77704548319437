import { Inject, injectable } from 'inversify-props';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';
import { IpdcProduct } from '@govflanders/mbp-admin-panel-shared';
import { DEFAULT_PAGING_SIZE } from '@/constants';
import { SearchQueryData, SearchResponse } from '@/utility/definitions';

@injectable()
export class IpdcProductService {
  private static readonly BASE_URL: string = '/api/v1/ipdc/products';
  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  public async update(
    ipdcProduct: IpdcProduct,
  ): Promise<{ success: boolean; message?: IpdcProduct | number }> {
    return this._httpClient
      .put(`${IpdcProductService.BASE_URL}/${ipdcProduct.productId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ipdcProduct),
      })
      .then(response => response.json());
  }

  public async find(queryData: SearchQueryData): Promise<SearchResponse<IpdcProduct>> {
    const url = new URL(IpdcProductService.BASE_URL, window.location.origin);
    url.searchParams.set('query', queryData.query);
    url.searchParams.set('ipdc', queryData.ipdc);
    url.searchParams.set('offset', queryData.offset);
    url.searchParams.set('limit', queryData.limit || DEFAULT_PAGING_SIZE);

    // Resolve search response data.
    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  public get(id: string): Promise<IpdcProduct> {
    try {
      return this._httpClient
        .get(`${IpdcProductService.BASE_URL}/${id}`, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then(response => response.json());
    } catch (error) {
      console.log(error);
    }
  }
}
